import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Carousel from "react-bootstrap/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import Image from "../Image";
import "./RoletniShtori.scss";

const RoletniShtori = ({ frontmatter }) => {
  return (
    <div className="container d-flex flex-wrap pt-5 pt-lg-6">
      <h3 className="col-12 pb-2">{frontmatter.header}</h3>
      <div className="d-flex justify-content-lg-start justify-content-center flex-column col-12 px-md-0 px-2 col-lg-6 px-0">
        <Carousel
          variant="dark"
          className="col-md-8 col-lg-12 align-self-center flex-md-shrink-1"
          indicators={false}
          nextIcon={<FontAwesomeIcon icon={faChevronRight} size="lg" color="#212529" />}
          prevIcon={<FontAwesomeIcon icon={faChevronLeft} size="lg" color="#212529" />}
        >
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="vanshni_shtori/1.jpg"
              alt="Външна ролетна щора"
              style={{ height: "300px" }}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="vanshni_shtori/2.jpg"
              alt="Външна ролетна щора"
              style={{ height: "300px" }}
            />
          </Carousel.Item>
        </Carousel>
        <div className="row justify-content-center my-3">
          <Link className="btn btn-primary" to="/gallery/#vanshni_shtori" activeClassName="active">
            Към Галерията
          </Link>
        </div>
      </div>
      <div className="col-lg-6">
        Ние можем да Ви предложим PVC и алуминиеви външни ролетни щори. Благодарение на тях можете
        да напълно да предпазите вашият дом или офис от слънчева светлина и недоброжелателни
        погледи. Чрез тях се постига по-добра звуко- и топлоизолация, което ще Ви предпази от студа
        през зимата и от горещините през лятото.
        <br />
        <br />
        Освен практични, външните щори предлагани от Бисер Пласт Партнерс са и стилни. Има множество
        цветове, от които можете да си изберете, за да паснат на екстериорът на Вашата сграда.
        <br />
        <br />
        Не се притеснявайте и за тяхната поддръжка – могат да бъдат лесно почистени с гъба и
        препарат.
      </div>
    </div>
  );
};

RoletniShtori.propTypes = {
  frontmatter: PropTypes.object,
};

RoletniShtori.defaultProps = {
  frontmatter: {},
};

export default RoletniShtori;
